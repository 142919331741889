
import Multiselect from "@vueform/multiselect";
import { defineComponent, ref, onMounted, nextTick } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { PasswordMeterComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";
import moment from "moment";

export default defineComponent({
  name: "change-password",
  components: {
    Field,
    Form,
    ErrorMessage,
    Datepicker,
    Multiselect,
  },
  setup() {
    const phone = ref(null);
    const image = ref("");
    const customMessage = ref("");
    const store = useStore();
    const groupList = ref([]);
    const optionValue = ref("");
    const router = useRouter();
    const templates = ref([]);
    const selectednumber = ref([]);
    const mseltempids = ref([]);
    const route = useRoute();
    const profile = ref<any>(false);
    const submitButton = ref<HTMLButtonElement | null>(null);
    const loading = ref<boolean>(false);
    const page = ref<string>("");
    const numbers = ref([]);
    const sms = ref("");
    const tags = ref([]);
    const group = ref(null);
    // const value = ref('');
    const registration = Yup.object().shape({
      group_id: Yup.string().required().label("Select Group"),
      scheduletype: Yup.string().required().label("Select Schedule"),
      messagetype: Yup.string().required().label("Select Message Type"),

      // plivoNumber: Yup.string().required().label("Select Number"),
    });
    const date = ref<HTMLButtonElement | null>(null);
    const groupListData = ref("");
    const example4 = ref({
      mode: "tags",
      options: [
        { value: "batman", label: "Batman" },
        { value: "robin", label: "Robin" },
        { value: "joker", label: "Joker" },
      ],
      searchable: true,
      createTag: true,
    });
    const tagListData = ref([]);
    const message_count = ref("");
    onMounted(() => {
      checkTimeFunction();
      getGroup();
      getNumbers();
      getTemplate();
      getTaglist();
      setCurrentPageBreadcrumbs("Bulk Message", []);
      store.dispatch(Actions.GET_AUTH_USER).then((data) => {
        //formData.value = data
        profile.value = data;
      });
      nextTick(() => {
        PasswordMeterComponent.bootstrap();
      });
      const id = route.params.id;
      if (id) {
        // getUser(id);
        page.value = "Edit";
      } else {
        page.value = "Add";
      }
    });
    const getGroup = () => {
      var request = {
        url: `group/all`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          // phone_number.value = data.data.mobileno;
          groupList.value = data.data;
          // console.log(data)
        }
      });
    };
    const getTaglist = () => {
      var request = {
        url: `tag`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          // phone_number.value = data.data.mobileno;
          tagListData.value = data.data;
          // console.log(data)
        }
      });
    };

    const getTemplate = () => {
      var request = {
        url: `template/get`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          // phone_number.value = data.data.mobileno;
          var arrTemplate: any = [];
          templates.value = data.data;
          for (let x of data.data) {
            var objTemplate = { value: x._id, label: x.title };
            arrTemplate.push(objTemplate);
          }
          example4.value.options = arrTemplate;
          // console.log('datadsdsd',data.data)
        }
      });
    };

    const getNumbers = () => {
      var request = {
        url: `number/broadcast`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          // phone_number.value = data.data.mobileno;
          numbers.value = data.data;
          // console.log('datadsdsd',data.data)
        }
      });
    };

    const selectOption = (e) => {
      var data = e.target.value;
      optionValue.value = data;
    };

    const messageBox = (msg) => {
      // customMessage.value = msg;
      // console.log(msg);
      //message_count.value = sms.value.length;
    };

    const selectMessagetype = (e) => {
      var data = e.target.value;
      groupListData.value = data;
    };
    const dateTime = (value) => {
      var value2 = moment(value).format("YYYY-MM-DD HH:mm:ss");
      return value2;
    };

    const onSubmitRegister = (values, { resetForm }) => {
      if (!showButton.value) {
        return false;
      }
      if (!isShowSubmitButton.value) {
        //alert('form not submit');
        return false;
      }
      //console.log(tags.value);
      loading.value = true;
      // Clear existing errors
      // eslint-disable-next-line
      //console.log(sms.value);
      //submitButton.value!.disabled = true;
      // Activate indicator
      //submitButton.value?.setAttribute("data-kt-indicator", "on");
      if (!values.selectednumber) {
        loading.value = false;
        Swal.fire("Alert!", "Please select at-least one contact.", "alert");
        return false;
      }
      if (values.selectednumber.length == 0) {
        Swal.fire("Alert!", "Please select at-least one contact.", "alert");
        loading.value = false;
      } else {
        const formData = new FormData();
        formData.append("group_id", values.group_id);
        formData.append("message_type", values.messagetype);
        if (values.messagetype == "customsmg") {
          formData.append("message", sms.value);
        } else {
          formData.append("template_id", JSON.stringify(mseltempids.value));
        }
        if (values.file != undefined) {
          formData.append("file", values.file[0]);
        }
        formData.append("randomize", values.randomize);
        formData.append("scheduletype", values.scheduletype);
        if (values.scheduletype == "schedule") {
          var sDate = dateTime(date.value);
          formData.append("schedule_date", sDate);
        }
        formData.append("number", JSON.stringify(values.selectednumber));
        formData.append("tag_id", JSON.stringify(tags.value));

        var request = {
          url: "bulk-message",
          data: formData,
        };
        let config = {
          header: {
            "Content-Type": "multipart/form-data",
          },
        };
        store
          .dispatch(Actions.POST, request)
          .then((data) => {
            if (data) {
              resetForm();
              Swal.fire({
                text: "You have successfully send your Message!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  router.push({ name: "user-manage-campaign" });
                }
                loading.value = false;
              });
            }
          })
          .catch(() => {
            const [error] = Object.keys(store.getters.getErrors);
            loading.value = true;
            Swal.fire({
              text: store.getters.getErrors[error],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      }

      // var arrNumbers:any = [];
      // for(let x of values.number){
      //    arrNumbers.push({ number: x });
      // }
      // values.number = arrNumbers;

      // if(values.plivoNumber == undefined){
      //    Swal.fire({
      //         text: "Please select Number!",
      //         icon: "warning",
      //         buttonsStyling: false,
      //         confirmButtonText: "Ok, got it!",
      //         customClass: {
      //           confirmButton: "btn fw-bold btn-danger",
      //         },
      //       })
      // }else if(values.file == undefined){
      //       Swal.fire({
      //         text: "Please select file!",
      //         icon: "warning",
      //         buttonsStyling: false,
      //         confirmButtonText: "Ok, got it!",
      //         customClass: {
      //           confirmButton: "btn fw-bold btn-danger",
      //         },
      //       })
      // }else if(values.file[0].type != 'image/png' && values.file[0].type != 'image/jpeg'){
      //     // console.log(values.file[0]);
      //      Swal.fire({
      //         text: "Please select file only .png .jpeg .jpg!",
      //         icon: "warning",
      //         buttonsStyling: false,
      //         confirmButtonText: "Ok, got it!",
      //         customClass: {
      //           confirmButton: "btn fw-bold btn-danger",
      //         },
      //       });
      // }else if(values.message == ""){
      //      Swal.fire({
      //         text: "Please Enter message!",
      //         icon: "warning",
      //         buttonsStyling: false,
      //         confirmButtonText: "Ok, got it!",
      //         customClass: {
      //           confirmButton: "btn fw-bold btn-danger",
      //         },
      //       });
      // }else {
      //   // console.log('dsds',values.file[0]);
      //   // console.log('form value', values.number);
      //   // console.log('fdfdf',image.value);
      //   const formData = new FormData();
      //   // var formDataVal = new FormData();
      //   formData.append('number', values.number);
      //   formData.append('plivoNumber',values.plivoNumber);
      //   formData.append('file',image.value);
      //   formData.append('message',values.message)
      //   var request = {
      //     url: 'single-message',
      //     data: formData,
      //   }
      //   // let config = {
      //   //     header : {
      //   //     'Content-Type' : 'multipart/form-data'
      //   //   }
      //   // }
      //   // console.log('request',request);
      //   store
      //   .dispatch(Actions.POST, request)
      //   .then((data) => {
      //     if(data){
      //       resetForm();
      //       Swal.fire({
      //         text: "You have successfully send your Message!",
      //         icon: "success",
      //         buttonsStyling: false,
      //         confirmButtonText: "Ok, got it!",
      //         customClass: {
      //           confirmButton: "btn fw-bold btn-light-primary",
      //         },
      //       }).then(function () {
      //         // router.push({ name: "dashboard" });
      //          resetForm();
      //       });
      //     }
      //   })
      //   .catch(() => {
      //     const [error] = Object.keys(store.getters.getErrors);
      //     Swal.fire({
      //       text: store.getters.getErrors[error],
      //       icon: "error",
      //       buttonsStyling: false,
      //       confirmButtonText: "Try again!",
      //       customClass: {
      //         confirmButton: "btn fw-bold btn-light-danger",
      //       },
      //     });
      //   });
      // }

      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      submitButton.value!.disabled = false;
    };

    const changefile = (e) => {
      const file = e.target.files[0];
      // const reader = new FileReader();
      // console.log('uploaded file',file.type);
      if (file.type != "image/png" && file.type != "image/jpeg") {
        Swal.fire({
          text: "Please select image only .png .jpeg .jpg!",
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn fw-bold btn-danger",
          },
        });
      } else {
        const file = e.target.files[0];
        image.value = file;
      }
      // reader.onload = function (e) {
      //   if(e.target){
      //   const text = e.target.result;
      // };
    };
    const contactCount = ref(0);
    const clickCount = ref(0);
    const isShowSubmitButton = ref(false);
    const groupChange = () => {
      isShowSubmitButton.value = false;
      //console.log(group.value);
      //var id = route.params.id;
      var request = {
        url: `contact/group/${group.value}`,
      };
      store.dispatch(Actions.GET, request).then((response) => {
        // console.log(response)
        clickCount.value = 0;
        contactCount.value = response.data;
      });
    };

    const showButton = ref(false);
    const checkTimeFunction = () => {
      //isShowSubmitButton.value = false
      //console.log(group.value);
      //var id = route.params.id;
      var request = {
        url: `user/get-time-button`,
      };
      store.dispatch(Actions.GET, request).then((response) => {
        showButton.value = false;
        if (response.data == "true") {
          showButton.value = true;
        }
        // // console.log(response)
        // clickCount.value = 0;
        // contactCount.value = response.data
      });
    };
    const clickAdd = () => {
      clickCount.value += 1;
      if (clickCount.value == contactCount.value) {
        isShowSubmitButton.value = true;
      } else {
        isShowSubmitButton.value = false;
      }
    };
    return {
      registration,
      onSubmitRegister,
      submitButton,
      loading,
      phone,
      page,
      changefile,
      image,
      getGroup,
      groupList,
      selectOption,
      selectMessagetype,
      optionValue,
      date,
      mseltempids,
      numbers,
      getNumbers,
      groupListData,
      selectednumber,
      example4,
      getTemplate,
      templates,
      tagListData,
      getTaglist,
      messageBox,
      customMessage,
      sms,
      tags,
      message_count,
      group,
      groupChange,
      clickCount,
      contactCount,
      clickAdd,
      isShowSubmitButton,
      showButton,
    };
  },
});
